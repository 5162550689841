<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" transition="dialog-bottom-transition" fullscreen>
      

      <v-card>
        <v-form v-on:submit.prevent="createItem" ref="modal_form">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text class="container mt-5">
            <!-- START FORM DIALOG -->

            <p class="text-h6 mb-5" v-if="item.id <= 0">Complete el formulario para crear una orden</p>
            <div class="row">
              <div class="col-md-12">
                <v-row>
                  <v-col md="3">
                    <!--Ahora se puede editar porque pidieron poder cambiarlo para facturar-->
                    <v-combobox
                      v-model="item.client"
                      data-vv-as="cliente"
                      name="client"
                      item-text="business_name"
                      item-value="id"
                      :items="clients"
                      label="Cliente*"
                      autocomplete="off"
                      v-validate="'required'"
                      v-on:change="clientChangeCombo($event)"
                      :disabled="isViewModal()"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("client") }}</span>
                  </v-col>
                  <v-col md="3">
                    <v-combobox
                      v-model="item.work_order.applicant"
                      data-vv-as="solicitante"
                      name="applicant"
                      item-text="name"
                      item-value="id"
                      :items="applicants"
                      label="Solicitante"
                      autocomplete="off"                      
                      :disabled="true"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("applicant") }}</span>
                  </v-col>
                  
                  <v-col md="3">
                    <v-text-field 
                      class="mt-6"
                      dense data-vv-as="nro_orden" 
                      label="N° Orden" 
                      name="work_order_id" 
                      v-model="item.work_order.id"
                      :disabled="true" />
                    <span class="red--text">{{ errors.first("nro_orden") }}</span>
                  </v-col>
                  
                  <v-col md="3">
                    <DatePickerEsp
                      label="Fecha creación"                      
                      :date="item.creation_date"
                      :isRequired="false"
                      name="creation_date"
                      :isViewModal="true"
                      @setDate="
                        (date) => {
                          //item.creation_date = date;
                        }
                      "
                    />
                  </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="item.items" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
                  <template v-slot:item="props">
                    <tr>
                      <td>{{ props.index + 1 }}</td>
                      <td>{{ props.item.asignated_task.task.name }}</td>
                      <td class="text-center">
                        <v-text-field                           
                          dense data-vv-as="spent"                           
                          name="spent" 
                          v-model="props.item.spent"
                          :disabled="isViewModal()" />
                      </td>
                      <td class="text-center">
                        <v-text-field                           
                          dense data-vv-as="spent_writing_right"                           
                          name="spent_writing_right" 
                          v-model="props.item.spent_writing_right"
                          :disabled="isViewModal()" />
                      </td>
                      <td class="text-center">
                        <v-text-field                           
                          dense data-vv-as="spent_notarial_contribution"                           
                          name="spent_notarial_contribution" 
                          v-model="props.item.spent_notarial_contribution"
                          :disabled="isViewModal()" />
                      </td>
                      <td class="text-center">
                        <v-text-field
                          dense data-vv-as="honorary"
                          name="honorary"
                          v-model="props.item.honorary"
                          :disabled="isViewModal()" />
                      </td>
                      <td class="text-center">
                        <v-text-field
                          dense data-vv-as="legalizacion"
                          name="legalizacion"
                          v-model="props.item.legalizacion"
                          :disabled="isViewModal()" />                        
                      </td>
                      <td class="text-center">
                        <v-text-field
                          dense data-vv-as="apostilla"
                          name="apostilla"
                          v-model="props.item.apostilla"
                          :disabled="isViewModal()" />                        
                      </td>
                      <td class="text-center">
                        <v-text-field
                          dense data-vv-as="apostilla_urgente"
                          name="apostilla_urgente"
                          v-model="props.item.apostilla_urgente"
                          :disabled="isViewModal()" />                        
                      </td>
                      <td class="text-center">
                        <v-text-field
                          dense data-vv-as="anexo_spent"
                          name="anexo_spent"
                          v-model="props.item.anexo_spent"
                          :disabled="isViewModal()" />                        
                      </td>
                      <td class="text-center">
                        <v-text-field
                          dense data-vv-as="anexo_honorary"
                          name="anexo_honorary"
                          v-model="props.item.anexo_honorary"
                          :disabled="isViewModal()" />                        
                      </td>
                      <td class="text-center">                        
                        {{ calculateItemTotal(props.item) }}
                      </td>
                                                                  
                      <td class="text-center">                        
                        <v-tooltip bottom v-if="!isViewModal()">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small color="red" @click="removeItem(props.item)"> indeterminate_check_box </v-icon>
                          </template>
                          <span>Remover</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:header="props">
                    <thead>
                      <tr>
                        <th colspan="1">Numero</th>
                        <th colspan="1">Tarea</th>
                        <th colspan="6" class="text-center">Importes</th>
                        <th colspan="1" class="text-center">Total</th>
                        <th colspan="1" class="text-center">Accion</th>
                      </tr>
                    </thead>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-card-text>

          <v-card-actions class="card-footer-fullscreen">
            <v-spacer></v-spacer>
            <v-btn color="error" min-width="100" @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" min-width="100" :loading="loading" type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import UserType from "@/constants/user_type";
import ConstantEmployeeTypes from "@/constants/employee_types.js";
import { orderService } from "@/libs/ws/orderService";
import { generalService } from "@/libs/ws/generalService";
import { fileUtils } from "@/libs/utils/fileUtils";
import DatePickerEsp from "@/components/DatePickerEsp.vue";

export default {
  props: {
    updateDialog: Function,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  components: { DatePickerEsp },
  data() {
    return {
      loading: false,
      menu: false,
      show: false,
      dialog_task: false,
      clients: [],
      employees: [],
      applicants: [],
      editedIndex: -1,
      viewdIndex: -1,
      headers: [
        { text: "", value: "number", sortable: false },
        { text: "", value: "task", sortable: false },
        { text: "Gasto", value: "spent", sortable: false, class: "bg-grey" },
        { text: "Derecho de escritura", value: "spent_writing_right", sortable: false, class: "bg-grey" },
        { text: "Aporte notarial", value: "spent_notarial_contribution", sortable: false, class: "bg-grey" },
        { text: "Honorarios", value: "honorary", sortable: false, class: "bg-grey" },
        { text: "Legalizacion", value: "legalizacion", sortable: false, class: "bg-grey" },
        { text: "Apostilla", value: "apostilla", sortable: false, class: "bg-grey" },
        { text: "Apostilla urgente", value: "apostilla_urgente", sortable: false, class: "bg-grey" },
        { text: "Anexo Gasto", value: "anexo_spent", sortable: false, class: "bg-grey" },
        { text: "Anexo Honorarios", value: "anexo_honorary", sortable: false, class: "bg-grey" },
        { text: "", value: "total", sortable: false },
        { text: "", value: "", sortable: false },
      ],
      asignatedTaskItemEdit: null,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadComboData();
    this.setWorkOrderEmployee();    
  },
  methods: {
    setWorkOrderEmployee() {
      if (localStorage.getItem("user_type") == UserType.EMPLOYEE && JSON.parse(localStorage.getItem("app_object")).employee_type == ConstantEmployeeTypes.EMPLOYEE) {
        this.item.employee = JSON.parse(localStorage.getItem("app_object"));
      }
    },
    isViewModal() {
      return this.formBtn == "";
    },
    getPriorityName(p) {
      switch (p) {
        case 1:
          return "Baja";
        case 2:
          return "Media";
        case 3:
          return "Alta";
      }
    },
    loadComboData() {
      generalService.filter({}, Constant.SECTION_CLIENTS).then((response) => {
        this.clients = response.result;
      });
      generalService.filter({ employee_type: ConstantEmployeeTypes.TYPE_EMPLOYEE }, Constant.SECTION_EMPLOYEES).then((response) => {
        this.employees = response.result;
      });

      if (this.item.client != null && this.item.client.id > 0) this.clientChangeCombo();
    },
    clientChangeCombo(event) {
      var param = { client: this.item.client.id };
      generalService.filter(param, Constant.SECTION_APPLICANTS).then((response) => {
        this.applicants = response.result;
      });
    },
    getDataToRequest() {      
      console.log(this.item)
      var data = {
        id: this.item.id,       
        work_order_id: this.item.work_order.id,
        client: this.item.client.id,
        items_arr:[]      
      };
      
      //Load asignated_tasks
      this.item.items.forEach((remito_item) => {
        var i = {
          id: remito_item.id,
          remito_id: data.id,
          asignated_task_id: remito_item.asignated_task.id,
          spent: remito_item.spent,
          honorary: remito_item.honorary, 
          spent_notarial_contribution: remito_item.spent_notarial_contribution,
          spent_writing_right: remito_item.spent_writing_right,
          legalizacion: remito_item.legalizacion,
          apostilla: remito_item.apostilla,
          apostilla_urgente: remito_item.apostilla_urgente,
          anexo_spent: remito_item.anexo_spent,
          anexo_honorary: remito_item.anexo_honorary,
        };
       
        data.items_arr.push(i);
      });      
      console.log(data);
      return data;
      
    },
    createItem(e, nextFlag = false) {
      return this.$validator.validate().then((valid) => {
        if (valid) {
          if (e != null) e.preventDefault();
          const data = this.getDataToRequest();          
          this.loading = true;
          if (this.item.id > 0) {
            return this.service
              .update(data)
              .then((response) => {
                console.log(response);
                this.loading = false;
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
                this.loading = false;
              });
          } else {
            return orderService
              .create(data)
              .then((response) => {
                this.loading = false;
                console.log(response);
                if (response.result.id > 0) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                  return response;
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                  return response;
                }
              })
              .catch((response) => {
                console.log(response);
                this.loading = false;
                this.$toast.error(response.msg, "Error");
                return response;
              });
          }
        } else {
          console.log("form incompleto", valid);
          console.log("form errors", this.errors);
        }
      });
    },
    removeItem(item) {
      const index = this.item.items.indexOf(item);
      if (index > -1) {
        this.item.items.splice(index, 1);
      }
    },    
    calculateItemTotal(item){
      return (        
        parseFloat(item.spent) +
        parseFloat(item.spent_writing_right) +
        parseFloat(item.spent_notarial_contribution) +
        parseFloat(item.honorary) +
        parseFloat(item.legalizacion) +
        parseFloat(item.apostilla) +
        parseFloat(item.apostilla_urgente) +
        parseFloat(item.anexo_spent) +
        parseFloat(item.anexo_honorary)
      );
    }
  },
};
</script>
