<template>
  <div>
    <v-dialog v-model="open" max-width="900" @keydown.esc="close()" @click:outside="close()">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>

        <v-card-text>
          <p class="text-h6 mb-5">Complete el formulario para crear una factura con los remitos seleccionados</p>

          <div class="row">
            <div class="col-md-12">
              <v-row>
                <v-col md="6">
                  <v-combobox
                    v-model="invoice.emitter"
                    data-vv-as="emitter"
                    name="emitter"
                    item-text="name"
                    item-value="id"
                    :items="emitters"
                    label="Emisor*"
                    autocomplete="off"
                    v-validate="'required'"
                    v-on:change="
                      emitterChangeCombo($event);
                      getCbtesTipos();
                    "
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("emitter") }}</span>
                </v-col>

                <v-col md="6">
                  <v-combobox
                    v-model="invoice.receiver"
                    data-vv-as="receiver"
                    name="receiver"
                    item-text="name"
                    item-value="id"
                    :items="receivers"
                    label="Receptor*"
                    autocomplete="off"
                    v-validate="'required'"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("receiver") }}</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="6">
                  <v-combobox
                    v-model="invoice.cbte_tipo"
                    data-vv-as="cbte_tipo"
                    name="cbte_tipo"
                    item-text="Desc"
                    item-value="Id"
                    :items="cbte_tipos"
                    label="Tipo de comprobante*"
                    autocomplete="off"
                    v-validate="'required'"
                    class="overflow-auto"
                    filled
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("cbte_tipo") }}</span>
                </v-col>

                <v-col md="3">
                  <v-combobox v-model="invoice.pto_vta" data-vv-as="pto_vta" name="pto_vta" :items="ptosvtas" label="Punto de venta*" autocomplete="off" v-validate="'required'"></v-combobox>
                  <span class="red--text">{{ errors.first("pto_vta") }}</span>
                </v-col>

                <v-col md="3">
                  <DatePickerEsp
                    :initToday="true"
                    label="Fecha"
                    :date="invoice.cbte_fch"
                    :isRequired="true"
                    name="cbte_fch"
                    @setDate="
                      (date) => {
                        invoice.cbte_fch = date;
                      }
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4">
                  <DatePickerEsp
                    :initToday="true"
                    label="Periodo desde (Min: -10 días de la fecha de emisión)"
                    :date="invoice.cbte_fch_serv_desde"
                    :isRequired="true"
                    name="cbte_fch_serv_desde"
                    @setDate="
                      (date) => {
                        invoice.cbte_fch_serv_desde = date;
                      }
                    "
                  />
                </v-col>
                <v-col md="4">
                  <DatePickerEsp
                    :initToday="true"
                    label="Periodo hasta (Max: +10 días de la fecha de emisión)"
                    :date="invoice.cbte_fch_serv_hasta"
                    :isRequired="true"
                    name="cbte_fch_serv_hasta"
                    @setDate="
                      (date) => {
                        invoice.cbte_fch_serv_hasta = date;
                      }
                    "
                  />
                </v-col>
                <v-col md="4">
                  <DatePickerEsp
                    :initToday="true"
                    label="Fecha Vto. Pago"
                    :date="invoice.fch_vto_pago"
                    :isRequired="true"
                    name="fch_vto_pago"
                    @setDate="
                      (date) => {
                        invoice.fch_vto_pago = date;
                      }
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4">
                  <v-combobox
                    v-model="invoice.concepto"
                    data-vv-as="concepto"
                    name="concepto"
                    item-text="Desc"
                    item-value="Id"
                    :items="conceptos"
                    label="Concepto*"
                    autocomplete="off"
                    v-validate="'required'"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("concepto") }}</span>
                </v-col>

                <v-col md="4">
                  <v-combobox
                    v-model="invoice.moneda"
                    data-vv-as="moneda"
                    name="moneda"
                    item-text="Desc"
                    item-value="Id"
                    :items="monedas"
                    label="Moneda*"
                    autocomplete="off"
                    v-validate="'required'"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("moneda") }}</span>
                </v-col>

                <v-col md="4">
                  <v-text-field
                    v-if="invoice.moneda != null && invoice.moneda.Id !== 'PES'"
                    autocomplete="off"
                    data-vv-as="mon_cotiz"
                    label="Cotización de la moneda*"
                    name="mon_cotiz"
                    v-validate="'decimal|required'"
                    v-model="invoice.mon_cotiz"
                  >
                  </v-text-field>
                  <span class="red--text">{{ errors.first("mon_cotiz") }}</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4">
                  <v-combobox
                    v-model="invoice.condition_vta"
                    data-vv-as="condition_vta"
                    name="condition_vta"
                    :items="conditions"
                    label="Condicion*"
                    autocomplete="off"
                    v-validate="'required'"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("condition_vta") }}</span>
                </v-col>
              </v-row>

              <!-- REMITOS -->
              <v-row>
                <v-col md="12">
                  <v-data-table class="pb-4" :headers="header_remitos" :items="this.invoice.remitos" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
                    <template v-slot:item="props">
                      <tr>
                        <td>{{ props.item.id.toString().padStart(7, "0") }}</td>
                        <td>{{ props.item.work_order.id.toString().padStart(7, "0") }}</td>
                        <td class="text-right">{{ formatUtils.formatPrice(props.item.grouped_items.no_taxed_total) }}</td>
                        <td class="text-right">{{ formatUtils.formatPrice(props.item.grouped_items.taxed_total) }}</td>
                        <td class="text-right">{{ formatUtils.formatPrice(props.item.grouped_items.taxed_total * 0.21) }}</td>
                        <td class="text-right">
                          {{ formatUtils.formatPrice(props.item.grouped_items.no_taxed_total + props.item.grouped_items.taxed_total + props.item.grouped_items.taxed_total * 0.21) }}
                        </td>
                        <td class="text-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" small color="red" @click="removeDetail(props.item, 'remitos')"> indeterminate_check_box </v-icon>
                            </template>
                            <span>Remover</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <!-- ITEMS MANUALES -->
              <v-row>
                <v-col md="12">
                  <b>Detalles personalizados</b>

                  <v-btn small color="blue-grey" class="ml-5 text-white" @click="addCustomDetail()">
                    <v-icon big color="green"> add </v-icon>
                    <span>Agregar</span>
                  </v-btn>
                </v-col>
                <v-col md="12">
                  <v-data-table class="pb-4" :headers="header_custome" :items="this.invoice.custom_items" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
                    <template v-slot:item="props">
                      <tr>
                        <td>
                          <v-text-field autocomplete="off" name="desc" placeholder="Descripción*" dense type="text" v-model="props.item.desc" class="mt-3" />
                          <span class="red--text">{{ errors.first("description") }}</span>
                        </td>
                        <td>
                          <v-text-field autocomplete="off" name="quantity" placeholder="Cantidad*" dense v-validate="'decimal'" type="number" v-model="props.item.quantity" class="mt-3" />
                          <span class="red--text">{{ errors.first("quantity") }}</span>
                        </td>
                        <td>
                          <v-text-field autocomplete="off" name="price" placeholder="Precio*" dense v-validate="'decimal'" type="number" v-model="props.item.price" class="mt-3" />
                          <span class="red--text">{{ errors.first("price") }}</span>
                        </td>
                        <td>
                          <v-combobox
                            v-model="props.item.iva"
                            data-vv-as="iva"
                            name="iva"
                            item-text="Desc"
                            item-value="Id"
                            :items="ivas"
                            label="IVA*"
                            autocomplete="off"
                            v-validate="'required'"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("iva") }}</span>
                        </td>
                        <td class="text-right">{{ showTotalCustomeItem(props.item) }}</td>
                        <td class="text-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" small color="red" @click="removeDetail(props.item, 'custom')"> indeterminate_check_box </v-icon>
                            </template>
                            <span>Remover</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4"> Total IVA: {{ formatUtils.formatPrice(imp_iva) }} </v-col>
                <v-col md="4"> Total Gravado: {{ formatUtils.formatPrice(imp_neto) }} </v-col>
                <v-col md="4"> Total No Gravado: {{ formatUtils.formatPrice(imp_tot_conc) }} </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <b>Total: {{ formatUtils.formatPrice(imp_total) }}</b>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="close()"> Cancelar </v-btn>

          <v-btn color="green darken-1" text @click="generateInvoice" :loading="loading"> Generar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Constant from "@/constants/sections";
import { vouchersService } from "@/libs/ws/vouchersService";
import { emmiterService } from "@/libs/ws/emmiterService";
import { billingGeneralService } from "@/libs/ws/billingGeneralService";
import { receiversService } from "@/libs/ws/receiversService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import { formatUtils } from "@/libs/utils/formatUtils";

export default {
  components: { DatePickerEsp },
  props: {
    title: String,
    remitos: Array,
    checkbox_label: String,
    open: Boolean,
    cancel: Function,
    accept: Function,
    loadList: Function,
    loading: {
      default: false,
      checkbox: false,
      type: Boolean,
    },
  },
  data: () => ({
    formatUtils: formatUtils,
    checkbox: false,
    emitters: [],
    receivers: [],
    cbte_tipos: [],
    ptosvtas: [],
    conceptos: [],
    monedas: [],
    ivas: [],
    invoice: {
      emitter: null,
      receiver: null,
      cbte_tipo: null,
      pto_vta: null,
      cbte_fch: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      cbte_fch_serv_desde: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      cbte_fch_serv_hasta: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      fch_vto_pago: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      concepto: null,
      moneda: null,
      mon_cotiz: 1,
      remitos: [],
      custom_items: [],
    },
    defaultInvoice: {
      emitter: null,
      receiver: null,
      cbte_tipo: null,
      pto_vta: null,
      cbte_fch: null,
      cbte_fch_serv_desde: null,
      cbte_fch_serv_hasta: null,
      fch_vto_pago: null,
      concepto: null,
      moneda: null,
      mon_cotiz: 1,
      remitos: [],
      custom_items: [],
    },
    //Se separaron de invoice para poder usar el watch sin hacer un bucle infinito
    imp_total: 0,
    imp_neto: 0,
    imp_tot_conc: 0,
    imp_iva: 0,
    header_remitos: [
      { text: "Nº Remito", value: "number", sortable: false, class: "bg-grey" },
      { text: "Nº Orden", value: "name", sortable: false, class: "bg-grey" },
      { text: "Total No Gravado", value: "grouped_items.no_taxed_total" },
      { text: "Total Gravado", value: "grouped_items.taxed_total" },
      { text: "Total IVA" },
      { text: "Total Remito", value: "total" },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
    header_custome: [
      { text: "Descripción", value: "description", sortable: false, class: "bg-grey" },
      { text: "Cantidad", value: "quantity", sortable: false, class: "bg-grey" },
      { text: "Precio", value: "price" },
      { text: "IVA", value: "iva" },
      { text: "Total", value: "total" },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
    client_id: 0,
    conditions: ["Contado", "Cuenta corriente"],
  }),
  watch: {
    open: function (val) {
      if (val) {
        this.init();
      }else{
        this.clients = [];
        this.invoice = JSON.parse(JSON.stringify(this.defaultInvoice));
      }
    },
    invoice: {
      handler: function (val) {
        this.calculateTotal();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadComboData();
    this.defaultInvoice = JSON.parse(JSON.stringify(this.invoice));
  },
  methods: {
    init() {
      this.invoice.remitos = this.remitos;
      this.client_id = this.remitos[0].client.id;
      this.loadComboData();
      this.errors.clear();
      this.$validator.reset();
    },
    close() {
      this.invoice = JSON.parse(JSON.stringify(this.defaultInvoice));
      this.clients = [];
      this.$emit("cancel");
      this.errors.clear();
    },
    loadComboData() {
      emmiterService.filter().then((response) => {
        this.emitters = response.result;
      });
      receiversService.filter({ client: this.client_id }).then((response) => {
        this.receivers = response.result;
      });
      if (this.invoice.emitter != null && this.invoice.emitter.id > 0) this.emitterChangeCombo();
    },
    getCbtesTipos() {
      if (this.invoice.emitter != null && this.invoice.emitter.id > 0) {
        billingGeneralService.cbteTiposInvoice(this.invoice.emitter.id).then((response) => {
          if (response.status) {
            this.cbte_tipos = response.result;
            this.invoice.cbte_tipo = response.result[0];
          }
        });
      }
    },
    receiverChangeCombo(event) {
      this.getCbtesTipos();
    },
    emitterChangeCombo(event) {
      this.getCbtesTipos();
      emmiterService.ptosvtas(this.invoice.emitter.id).then((response) => {
        if (response.status) {
          this.ptosvtas = response.result;
          this.invoice.pto_vta = response.result[0];
        }
      });
      emmiterService.conceptos(this.invoice.emitter.id).then((response) => {
        if (response.status) {
          this.conceptos = response.result;
          this.invoice.concepto = response.result[1]; //Servicios
        }
      });
      emmiterService.monedas(this.invoice.emitter.id).then((response) => {
        if (response.status) {
          this.monedas = response.result;
          this.invoice.moneda = response.result[0];
        }
      });
      emmiterService.ivas(this.invoice.emitter.id).then((response) => {
        if (response.status) {
          this.ivas = response.result;
        }
      });
    },
    removeDetail(item, colection_name) {
      if (colection_name == "remito") {
        const index_item = this.invoice.remitos.indexOf(item);
        if (index_item > -1) this.invoice.remitos.splice(index_item, 1);
      } else if (colection_name == "custom") {
        const index_item = this.invoice.custom_items.indexOf(item);
        if (index_item > -1) this.invoice.custom_items.splice(index_item, 1);
      }
    },
    generateInvoice() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          let data = this.getInvoiceParams();
          console.log("Params", data);
          vouchersService
            .create(data)
            .then((response) => {
              console.log("response", response);
              if (response.status) {
                this.$toast.success("La Facturase creó correctamente", "OK");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
                this.$emit("cancel");
                this.invoice = JSON.parse(JSON.stringify(this.defaultInvoice));
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.$toast.error(response.message, "Error");
                } else {
                  this.$toast.error(response.msg, "Error");
                }
              }
            })
            .catch((response) => {
              this.$toast.error(response.msg, "Error");
              this.loading = false;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$toast.error("Complete los campos requeridos", "Error");
        }
      });
    },
    getInvoiceParams() {
      let data = {
        emitter_id: this.invoice.emitter.id,
        receiver_id: this.invoice.receiver.id,
        cbte_tipo: this.invoice.cbte_tipo.Id,
        pto_vta: this.invoice.pto_vta,
        cbte_fch: this.invoice.cbte_fch,
        cbte_fch_serv_desde: this.invoice.cbte_fch_serv_desde,
        cbte_fch_serv_hasta: this.invoice.cbte_fch_serv_hasta,
        fch_vto_pago: this.invoice.fch_vto_pago,
        concepto: this.invoice.concepto.Id,
        mon_id: this.invoice.moneda.Id,
        mon_cotiz: this.invoice.moneda.Id == "PES" ? 1 : this.invoice.mon_cotiz,
        condition_vta: this.invoice.condition_vta,

        imp_total: this.imp_total,
        imp_neto: this.imp_neto,
        imp_tot_conc: this.imp_tot_conc,
        imp_iva: this.imp_iva,
        imp_op_ex: 0,
        imp_trib: 0,

        custom_items_arr: [],
        remitos_arr: [],
      };

      this.invoice.custom_items.forEach((item) => {
        const item_data = this.calculateCustomeItemTotals(item);
        data.custom_items_arr.push({
          desc: item.desc,
          iva_id: item.iva.Id,
          iva_desc: item.iva.Desc,
          imp_neto: item_data.imp_neto,
          imp_iva: item_data.imp_iva,
          price: item.price,
          quantity: item.quantity,
        });
      });

      this.invoice.remitos.forEach((remito) => {
        data.remitos_arr.push({ remito_id: remito.id });
      });

      return data;
    },
    calculateCustomeItemTotals(item) {
      if (item.price == null || item.quantity == null)
        return {
          imp_neto: 0,
          imp_iva: 0,
        };

      let imp_neto = formatUtils.formatPriceNumber(item.quantity * item.price);
      const IVA_ID_0 = 3;
      let imp_iva = item.iva == null || item.iva.id == IVA_ID_0 ? 0.0 : (formatUtils.formatPriceNumber(imp_neto) * formatUtils.formatPriceNumber(item.iva.Desc.replace("%", ""))) / 100;
      return {
        imp_neto: imp_neto,
        imp_iva: formatUtils.formatPriceNumber(imp_iva),
        total: formatUtils.formatPriceNumber(imp_neto) + formatUtils.formatPriceNumber(imp_iva),
      };
    },
    showTotalCustomeItem(item) {
      let totals = this.calculateCustomeItemTotals(item);
      return formatUtils.formatPrice(totals.total);
    },
    calculateTotal() {
      this.imp_total = 0.0;
      this.imp_neto = 0;
      this.imp_tot_conc = 0;
      this.imp_iva = 0;
      this.invoice.remitos.forEach((remito) => {
        this.imp_tot_conc += formatUtils.formatPriceNumber(remito.grouped_items.no_taxed_total);
        this.imp_iva += formatUtils.formatPriceNumber(parseFloat(remito.grouped_items.taxed_total) * 0.21);
        this.imp_neto += formatUtils.formatPriceNumber(remito.grouped_items.taxed_total);
        this.imp_total += formatUtils.formatPriceNumber(
          formatUtils.formatPriceNumber(remito.grouped_items.taxed_total) +
            formatUtils.formatPriceNumber(remito.grouped_items.no_taxed_total) +
            formatUtils.formatPriceNumber(parseFloat(remito.grouped_items.taxed_total) * 0.21)
        );
      });

      this.invoice.custom_items.forEach((item) => {
        let totals = this.calculateCustomeItemTotals(item);
        this.imp_tot_conc += totals.imp_iva == 0 ? totals.imp_neto : 0;
        this.imp_iva += totals.imp_iva;
        this.imp_neto += totals.imp_neto;
        this.imp_total += formatUtils.formatPriceNumber(totals.imp_neto) + formatUtils.formatPriceNumber(totals.imp_iva);
      });
    },
    addCustomDetail() {
      this.invoice.custom_items.push({
        desc: "",
        quantity: 0,
        price: 0,
        iva: null,
      });
    },
  },
};
</script>
